export default [
    {
        title: 'Reports',
        icon: 'ActivityIcon',
        children: [
            {
                title: 'reports.names.trading-overview',
                route: 'trading-overview',
                resource: 'trading-overview',
                action: 'read',
            },
            {
                title: 'reports.names.platform-report',
                route: 'platform-report',
                resource: 'platform-report',
                action: 'read',
            },
            {
                title: 'reports.names.country-report',
                route: 'country-report',
                resource: 'country-report',
                action: 'read',
            },
            {
                title: 'reports.names.brand-overview',
                route: 'brand-overview',
                resource: 'brand-overview',
                action: 'read',
            },
            {
                title: 'reports.names.brand-by-platform',
                route: 'brand-by-platform',
                resource: 'brand-by-platform',
                action: 'read',
            },
            {
                title: 'reports.names.marketing-planer',
                route: 'marketing-planer',
                resource: 'marketing-planer',
                action: 'read',
            },
        ]
    }
]
